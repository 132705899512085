import React from "react";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import SearchPractBox from "../../components/SearchBox/SearchPractBox";
import UserTable from "../../components/Tables/UserTable";
import Pagination from "../../components/Pagination/Pagination";
import { apiService } from "../../services/api_service";
import { alertActions } from "../../actions/alert-action";
import { connect } from "react-redux";
import { Modal, Spinner, Form, Button } from "react-bootstrap";
import MultiSelect from "../../components/MultiSelect/MultiSelect";
import Chip from "@material-ui/core/Chip";
import FilerButton from "../../components/UserMenu/FilerButton";
import { SortArray, nameRegex, usernameRegex } from "../../constants";
import { getPartnerColour } from "../../helpers/partner";

let graph_color = "#09A79E";
// let light_color = "#09a79e69";

class UserManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: this.props.user?.email || "",
      practitioner_id: this.props.user?.id || "",
      partner_id: "",
      clinic_id: "",
      name: "",
      type: "",
      loading: false,
      request_loading: false,
      user_details: [],
      dropdown: [{ label: "Practitioner", value: "practitioner" }],
      addButton: false,
      select: "all",
      select_all: "",
      show: false,
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      modal: {},
      access: [],
      accessChecked: [],
      current_pract: "",
      current_id: "",
      current_type: "",
      current_partner: 1,
      user_type: "",
      emailError: false,
      emailExist: false,
      user_nameError: false,
      first_nameError: false,
      phoneError: false,
      partner: [],
      clinic: [],
      practitioner: [],
      clinic_name: "",
      partner_name: "",
      users: [],
      usersCollection: [],
      total: 0,
      limit: 10,
      skip: 0,
      pages: [],
      request_access: false,
      search: "",
      filter: "",
      filter_type: "",
      showPatients: false,
      currentUserId: "",
      // kit assign
      kitId: "",
      kitSearch: false,
      assignError: false,
      assignErrorMsg: "",
      // blood kit assign
      bloodKitId: "",
      bloodKitSearch: false,
      assignBloodKitError: false,
      assignBloodKitErrorMsg: "",
      blood_users: [],

      edit_id: "",
      selectedPartner: "",
      selectedClinic: "",
      disablePartnerDropdown: false,
      disableClinicDropdown: false,
      blood_test_access: false,
    };
  }

  async componentWillReceiveProps(nextProps) {
    this.setState({ request_loading: true });
    try {
      let practitioner_details = nextProps.user;
      if (practitioner_details._id) {
        let type = practitioner_details?.type;
        let partner_id = practitioner_details?.partner_id;

        graph_color = getPartnerColour(partner_id)

        // let practitioner_details = await apiService.practitionerDetails()

        this.getUsersList();
        let dropdown = [];
        if (practitioner_details.type === "clinic") {
          dropdown = [
            { label: "All", value: "all" },
            { label: "Admin", value: "admin" },
            { label: "Practitioner", value: "practitioner" },
          ];
        } else if (practitioner_details.type === "partner") {
          dropdown = [
            { label: "All", value: "all" },
            { label: "Admin", value: "admin" },
            { label: "Sub-Partner", value: "clinic" },
            { label: "Practitioner", value: "practitioner" },
          ];
        } else {
          dropdown = [
            { label: "All", value: "all" },
            { label: "Admin", value: "admin" },
            { label: "Partner", value: "partner" },
            { label: "Sub-Partner", value: "clinic" },
            { label: "Practitioner", value: "practitioner" },
          ];
        }
        let pid = btoa(practitioner_details._id);
        if (type === "admin") {
          console.log("admin");
          // await this.practitionerList({practitioner_id:pid})
          await this.partnerList({ practitioner_id: pid });
          await this.subPartnerList({ practitioner_id: pid });
        } else if (type === "partner") {
          console.log("partner");
          // await this.practitionerList({})
          await this.subPartnerList({ practitioner_id: pid });
        } else {
          // await this.practitionerList({})
        }

        this.bloodTestAccess(practitioner_details.access_controls);

        this.setState({
          email: practitioner_details.email,
          practitioner_id: practitioner_details._id,
          partner_id: practitioner_details.partner_id,
          clinic_id: practitioner_details.clinic_id,
          name:
            practitioner_details.first_name + practitioner_details.last_name,
          type: practitioner_details.type,
          // user_details: user_details,
          // total: total,
          // pages: pages,
          user_type: practitioner_details.type,
          current_type: practitioner_details.type,
          current_pract: practitioner_details._id,
          dropdown,
          request_loading: false,
          currentUserId: pid,
          ...practitioner_details,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  bloodTestAccess(access) {
    let blood_test_access = false;
    if (access && access.blood_test) blood_test_access = true;
    this.setState({ blood_test_access });
  }

  async getUsersList() {
    const {
      select,
      search,
      filter,
      skip,
      limit,
      selectedPartner,
      selectedClinic,
    } = this.state;
    // console.log("email", email, "select", select, "search", search, "filter", filter, "skip", skip, "limit", limit)

    let condition = { skip: skip * limit, limit };

    if (select) {
      condition = { ...condition, type: select };
    }
    if (search) {
      condition = { ...condition, search };
    }
    if (Object.keys(filter).length != 0) {
      // console.log("filter before", filter)

      if (selectedPartner == "select") {
        delete filter.partner_id;
      }
      if (selectedClinic == "select") {
        delete filter.clinic_id;
      }
      // console.log("filter after", filter)
      condition = { ...condition, ...filter };
    }
    // console.log("condition", condition);
    let users = await apiService.userManagementGet(condition);
    let total = users[0].total.length > 0 ? users[0].total[0].count : 0;
    let pages = [...Array(Math.ceil(total / this.state.limit)).keys()];
    let user_details = users[0].users;
    this.setState({ user_details, total, pages, loading: false });
  }
  // async updateList(){
  //     try {
  //         let users = await apiService.userManagementGet(this.state.email, this.state.select, undefined, this.state.limit, this.state.skip*this.state.limit)
  //         let total = users[0].total.length>0? users[0].total[0].count: 0
  //         let pages = [...Array(Math.ceil(total/this.state.limit)).keys()];
  //         let user_details = users[0].users
  //         this.setState({user_details, total, pages, skip:0})
  //     } catch (error) {
  //         console.log("error",error)
  //     }
  // }

  async updateStatus(updated_user) {
    updated_user.loading = true;
    this.setState({
      data: this.state.user_details.map((el) =>
        el.id === updated_user.id ? updated_user : el
      ),
    });
    let userId = Buffer.from(String(updated_user._id)).toString("base64");
    let requestAccess = await apiService.requestAccess(userId);
    if (requestAccess.code === 200) {
      updated_user.report_access_status = "Request Pending";
      updated_user.is_accepted = false;
    }
    updated_user.loading = false;
    this.setState({
      data: this.state.user_details.map((el) =>
        el.id === updated_user.id ? updated_user : el
      ),
    });
  }

  // async handleFilter () {
  //     let partner = await apiService.userPractPartner(this.state.email, this.state.select)
  //     let clinic = await apiService.userPractClinic(this.state.email, this.state.select)
  //     this.setState({partner: partner, clinic})
  // }

  // async updateUsersList(userList, search){
  //     console.log("updateUserList", userList, search)
  //     let total = userList[0].total.length>0? userList[0].total[0].count: 0
  //     let pages = [...Array(Math.ceil(total/this.state.limit)).keys()];
  //     let user_details = userList[0].users
  //     this.setState({user_details:user_details, total, pages, skip: 0, search})
  // }

  handleSearch(search_item) {
    // console.log("search item", search_item);
    this.setState({ search: search_item, skip: 0 }, () => this.getUsersList());
  }

  handleFilterSelect(value) {
    console.log("filter value", value, this.state.type);
    let disablePartnerDropdown = false,
      disableClinicDropdown = false;
    let { selectedPartner, selectedClinic } = this.state;

    if (value == "admin" || value == "partner") {
      disablePartnerDropdown = true;
      disableClinicDropdown = true;
      selectedPartner = "select";
      selectedClinic = "select";
    } else if (value == "clinic") {
      disableClinicDropdown = true;
      selectedClinic = "select";
    }
    this.setState(
      {
        select: value,
        disablePartnerDropdown,
        disableClinicDropdown,
        skip: 0,
        selectedPartner,
        selectedClinic,
      },
      () => this.getUsersList()
    );
  }

  handleAccessChanges(checked) {
    let modal = this.state.modal;
    modal.access_controls = checked;
    this.setState({ accessChecked: checked, modal });
  }

  async handleCustomerSearch(e) {
    if (e.charCode === 13) {
      console.log("kit", this.state.kitId, this.state.users);
      let { email, partner_id, kitId, modal, users, clinic_id } = this.state;
      console.log(email, partner_id, kitId, modal, clinic_id);
      partner_id = partner_id || modal.partner_id;
      console.log("partner_id in kit id search", partner_id);

      if (!partner_id) {
        this.setState({
          assignError: true,
          assignErrorMsg: "Please select a partner before searching kit",
        });
        return;
      }

      try {
        this.setState({ kitSearch: true });
        let user = await apiService.getUserList(
          partner_id,
          kitId.toUpperCase()
        );
        // console.log("users", user)
        user.map((items) => {
          items.username = items.first_name + " " + items.last_name;
          const kitPresent = users.find((u) => u._id == items._id);
          console.log("kitPresent", kitPresent);
          if (kitPresent) {
            this.setState({
              kitSearch: false,
              assignError: true,
              assignErrorMsg: "Kit already added",
            });
          } else {
            users = users.concat(user);
          }
        });
        this.setState({ users, kitId: "", kitSearch: false });
      } catch (error) {
        this.setState({
          kitSearch: false,
          assignError: true,
          assignErrorMsg: "No user found with this kit id",
        });
      }
    }
  }

  enterKitId(e) {
    let value = e.target.value;
    this.setState({ kitId: value, assignError: false, assignErrorMsg: "" });
  }

  removeUserKit(user) {
    console.log("remvoe e", user);
    let { users } = this.state;

    users = users.filter((u) => u._id != user._id);
    this.setState({ users });
  }

  // Blood kit search functions
  async handleBloodKitSearch(e) {
    if (e.charCode === 13) {
      // console.log("kit", this.state.kitId, this.state.users)
      let { email, partner_id, bloodKitId, modal, blood_users, clinic_id } =
        this.state;
      console.log(email, partner_id, bloodKitId, modal, clinic_id);
      partner_id = partner_id || modal.partner_id;
      console.log("partner_id in kit id search", partner_id);

      if (!partner_id) {
        this.setState({
          assignBloodKitError: true,
          assignBloodKitErrorMsg:
            "Please select a partner before searching kit",
        });
        return;
      }

      try {
        this.setState({ bloodKitSearch: true });
        let user = await apiService.getBloodUserList(
          email,
          partner_id,
          bloodKitId.toUpperCase()
        );
        // console.log("users", user)
        user.map((items) => {
          items.username = items.first_name + " " + items.last_name;
          const kitPresent = blood_users.find((u) => u._id == items._id);
          console.log("kitPresent", kitPresent);
          if (kitPresent) {
            this.setState({
              bloodKitSearch: false,
              assignBloodKitError: true,
              assignBloodKitErrorMsg: "Kit already added",
            });
          } else {
            blood_users = blood_users.concat(user);
          }
        });
        this.setState({ blood_users, bloodKitId: "", bloodKitSearch: false });
      } catch (error) {
        this.setState({
          bloodKitSearch: false,
          assignBloodKitError: true,
          assignBloodKitErrorMsg: "No user found with this kit id",
        });
      }
    }
  }

  enterBloodKitId(e) {
    let value = e.target.value;
    this.setState({
      bloodKitId: value,
      assignBloodKitError: false,
      assignBloodKitErrorMsg: "",
    });
  }

  removeBloodKit(user) {
    console.log("remvoe e", user);
    let { blood_users } = this.state;

    blood_users = blood_users.filter((u) => u._id != user._id);
    this.setState({ blood_users });
  }

  async populateAddModal(currentType, addType) {
    let partner;
    let { currentUserId } = this.state;

    console.log(this.state.partner_id, this.state.currentUserId, "handlead");
    switch (currentType) {
      case "admin":
        if (!["admin", "partner", "clinic", "practitioner"].includes(addType)) {
          console.log("Invalid user type!");
          return;
        }

        if (addType == "partner") {
          partner = await apiService.PartnerListNewNIN(addType);
        } else if (addType == "clinic" || addType == "practitioner") {
          partner = await apiService.userPractPartner(
            this.state.email,
            addType
          );
        }
        this.setState({ partner });
        break;
      case "partner":
        if (!["admin", "clinic", "practitioner"].includes(addType)) {
          console.log("Invalid user type!");
          return;
        }

        this.setState({
          modal: { ...this.state.modal, partner_id: currentUserId },
        });

        if (addType == "practitioner") {
          let clinic = await apiService.userPractClinic(
            this.state.email,
            addType,
            currentUserId
          );
          this.setState({ clinic });

          console.log("partner id, clinic", currentUserId, clinic);
        }

        break;

      case "clinic":
        if (!["admin", "practitioner"].includes(addType)) {
          console.log("Invalid user type!");
          return;
        }
        let clinic_id = Buffer.from(currentUserId, "base64").toString();
        this.setState({ modal: { ...this.state.modal, clinic_id } });
        break;

      case "practitioner":
        alert("No access to add other users");
        break;
    }
  }
  async handleAdd() {
    const { select, current_type, currentUserId } = this.state;

    await this.populateAddModal(current_type, select);

    if (select != "all") {
      this.setState({ loading: true });
      let apiBody = {};
      if (select == "admin") {
        if (current_type == "partner" || current_type == "clinic") {
          apiBody.id = currentUserId;
        }
      } else if (select == "clinic") {
        if (current_type == "partner") apiBody.id = currentUserId;
      } else if (select == "practitioner") {
        if (current_type == "clinic" || current_type == "partner")
          apiBody.id = currentUserId;
      }
      let data = await apiService.accessManagementUser(apiBody);

      let list = getAccess(data);
      this.setState({
        show: true,
        modal: {
          ...this.state.modal,
          access_controls: data,
          request_access: false,
        },
        access: list.list,
        loading: false,
      });
    }
  }

  async handleAddApi() {
    this.setState({ loading: true });
    let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
    let modal = this.state.modal;
    console.log("on add click", modal);
    modal.request_access = this.state.request_access;
    if (
      modal["email"] &&
      modal["user_name"] &&
      modal["first_name"] &&
      modal["phone"] &&
      !this.state.emailExist
    ) {
      let selected = this.state.select;
      if (selected == "admin") {
        modal.partner_id = this.state.partner_id;
      }

      console.log("data for api", modal);
      if (modal.clinic_id)
        modal = { ...modal, clinic_id: btoa(modal.clinic_id) };
      let data = await apiService.createManagementUser(
        this.state.email,
        selected,
        modal
      );
      if (Object.keys(data).length > 0) {
        if (this.state.users.length > 0 || this.state.blood_users.length > 0) {
          const geneticUsers = this.state.users.map((u) => u._id);
          const bloodUsers = this.state.blood_users.map((u) => u._id);
          const userCollection = [...new Set([...geneticUsers, ...bloodUsers])];
          console.log({ geneticUsers, bloodUsers, userCollection });
          let users = await apiService.assignUser(data._id, userCollection);
        }

        console.log("Successfully added user");
        this.setState({
          message: `Successfully added ${selected}`,
          loading: false,
          users: [],
          show: false,
          request_access: false,
        });
        this.getUsersList();
        // this.updateList()
        this.handleClose();
      } else {
        console.log("Something went wrong", data);
        this.setState({ message: "Something went wrong!", loading: false });
      }
    } else {
      this.setState({
        emailError: modal["email"]
          ? modal["email"].match(regexEmail)
          : modal["email"]
            ? false
            : true,
        user_nameError: modal["user_name"] ? false : true,
        first_nameError: modal["first_name"] ? false : true,
        phoneError: modal["phone"] ? false : true,
        loading: false,
      });
    }
  }

  async handleEdit(id) {
    this.setState({ loading: true });
    let { currentUserId } = this.state;
    let data = await apiService.viewManagementUser(this.state.select, btoa(id));
    let apiBody = {
      action: "edit",
      edit_id: Buffer.from(String(id)).toString("base64"),
    };
    if (data.type == "admin" || data.type == "partner") {
      apiBody.id = currentUserId;
    } else if (data.type == "clinic") {
      apiBody.id = data.partner_id;
    } else if (data.type == "practitioner") {
      apiBody.id = data.clinic_id
        ? Buffer.from(String(data.clinic_id)).toString("base64")
        : data.partner_id;
    }
    let access = await apiService.accessManagementUser(apiBody);
    let list = getAccess(access);
    let partner = await apiService.userPractPartner(
      this.state.email,
      this.state.select
    );
    let partner_name = "",
      partner_id = null;

    let count = 0;
    let showPatients = false;

    partner.map((items) => {
      if (items.partner_id == data.partner_id) {
        partner_name = items.first_name + " " + items.last_name;
        partner_id = items.partner_id;
      }
    });
    let clinic = await apiService.userPractClinic(
      this.state.email,
      this.state.select,
      partner_id
    );
    let clinic_name = "";
    clinic.map((items) => {
      if (items._id == data.clinic_id) {
        clinic_name = items.first_name + " " + items.last_name;
      }
    });
    data.access_controls = access;

    // let list = []
    // let users = await apiService.getUserList(this.state.email, data.partner_id)
    // users.map((items) => {
    //     items.username = items.first_name + " " + items.last_name
    // })
    // Object.entries(access).map(items=>{
    //     let value = items[0]
    //     let label = camelize(items[0].replace(/_/g, " "))
    //     let condition = false
    //     let children = []
    //     if(typeof(items[1]) == "boolean"){
    //         condition = items[1]
    //     }else{
    //         Object.entries(items[1]).map((item, index) =>{
    //             let cvalue = item[0]
    //             let clabel = item[0]
    //             let ccondition = item[1]
    //             children.push({"value": cvalue, "label": camelize(clabel.replace(/_/g, " ")), "condition": ccondition})
    //         })
    //     }
    //     list.push({value, label, condition, "children": children.length>0? children: undefined})
    // })
    // if( data.access_controls !== undefined && Object.keys(data.access_controls).length > 0){
    //     Object.entries(data.access_controls).map((items, ind) =>{
    //         count = 0
    //         if(typeof(items[1]) == "boolean"){
    //             if(items[1] === true){
    //                 list[ind].condition = items[1]
    //             }
    //         }else{
    //             Object.entries(items[1]).map((item, index) =>{
    //                 if(item[1] === true){
    //                     if(list[ind].children !== undefined){
    //                         let objIndex = list[ind].children.findIndex((obj => obj.value == item[0]));
    //                         if(objIndex !== -1) list[ind].children[objIndex].condition = item[1];
    //                         count++
    //                     }
    //                     if(count === Object.keys(items[1]).length){
    //                         list[ind].condition = true
    //                     }
    //                 }
    //             })
    //         }
    //     })
    // }
    if (data.type == "clinic" || data.type == "practitioner") {
      showPatients = true;
    }
    console.log("access control list", list);
    this.setState({
      showPatients,
      show1: true,
      loading: false,
      modal: data,
      access: list.list,
      request_access: data.request_access,
      partner: partner,
      clinic: clinic,
      clinic_name: clinic_name,
      partner_name: partner_name,
      edit_id: id,
    });
  }

  async handleEditApi() {
    this.setState({ loading: true });
    let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
    let modal = this.state.modal;
    modal.request_access = this.state.request_access;
    if (modal["email"] && modal["first_name"] && modal["phone"]) {
      let selected =
        this.state.select == "all" ? modal.type : this.state.select;
      const { first_name, last_name, phone, request_access, access_controls } =
        this.state.modal;

      let data = await apiService.editManagementUser(
        btoa(this.state.current_id),
        {
          first_name,
          last_name,
          phone,
          request_access,
          access_controls,
        }
      );

      if (this.state.users.length > 0 || this.state.blood_users.length > 0) {
        const geneticUsers = this.state.users.map((u) => u._id);
        const bloodUsers = this.state.blood_users.map((u) => u._id);
        const userCollection = [...new Set([...geneticUsers, ...bloodUsers])];
        console.log({ geneticUsers, bloodUsers, userCollection });
        let users = await apiService.assignUser(data._id, userCollection);
      }
      if (data) {
        // console.log("Successfully updated user");
        this.setState({
          message: `Successfully updated ${selected}`,
          loading: false,
          users: [],
          show1: false,
          request_access: false,
        });
        this.getUsersList();
        // this.updateList()
        this.handleClose();
      } else {
        console.log("Error case", data);
        this.setState({ message: data, loading: false, show1: false, users: [] });
      }
    } else {
      this.setState({
        emailError: modal["email"]
          ? modal["email"].match(regexEmail)
          : modal["email"]
            ? false
            : true,
        first_nameError: modal["first_name"] ? false : true,
        phoneError: modal["phone"] ? false : true,
        loading: false,
      });
    }
  }

  async handleView(id) {
    this.setState({ loading: true });
    let data = await apiService.viewManagementUser(this.state.select, btoa(id));
    let apiBody = {
      action: "edit",
      edit_id: btoa(id),
    };
    if (data.type == "partner" || data.type == "admin") {
      apiBody.id = this.state.currentUserId;
    } else if (data.type == "clinic") {
      apiBody.id = data.partner_id;
    } else if (data.type == "practitioner") {
      apiBody.id = data.clinic_id ? btoa(data.clinic_id) : data.partner_id;
    }

    let access = await apiService.accessManagementUser(apiBody);

    //console.log(data?.access_controls )
    console.log(access);
    let list = getAccess(access);
    let partner = await apiService.PartnerListNew(
      this.state.email,
      this.state.select
    );
    let partner_name = "";
    let clinic = await apiService.userPractClinic(
      this.state.email,
      this.state.select
    );
    let clinic_name = "";
    // let list = []
    let count = 0;
    partner.map((items) => {
      items.partner_id.map((item) => {
        if (item == data.partner_id) {
          partner_name = items.username;
        }
      });
    });
    clinic.map((items) => {
      if (items._id == data.clinic_id) {
        clinic_name = items.first_name + " " + items.last_name;
      }
    });
    data.access_controls = access;
    // Object.entries(access).map(items=>{
    //     let value = items[0]
    //     let label = items[0].toLocaleLowerCase()
    //     let condition = false
    //     let children = []
    //     if(typeof(items[1]) == "boolean"){
    //         condition = items[1]
    //     }else{
    //         Object.entries(items[1]).map((item, index) =>{
    //             let cvalue = item[0]
    //             let clabel = item[0].toLocaleLowerCase()
    //             let ccondition = item[1]
    //             children.push({"value": cvalue, "label": clabel, "condition": ccondition})
    //         })
    //     }
    //     list.push({value, label, condition, "children": children.length>0? children: undefined})
    // })
    // if(data.access_controls && Object.keys(data.access_controls).length > 0){
    //     Object.entries(data.access_controls).map((items, ind) =>{
    //         count = 0
    //         if(typeof(items[1]) == "boolean"){
    //             if(items[1] === true){
    //                 list[ind].condition = items[1]
    //             }
    //         }else{
    //             Object.entries(items[1]).map((item, index) =>{
    //                 if(item[1] === true){
    //                     list[ind].children[index].condition = item[1]
    //                     count++
    //                     if(count === Object.keys(items[1]).length){
    //                         list[ind].condition = true
    //                     }
    //                 }
    //             })
    //         }
    //     })
    // }
    this.setState({
      show2: true,
      loading: false,
      modal: data,
      access: list.list,
      clinic_name,
      partner_name,
    });
  }

  async handleDelete(id) {
    let data = await apiService.viewManagementUser(this.state.select, btoa(id));
    this.setState({ show3: true, modal: data });
  }
  async handleDeleteApi() {
    let data = await apiService.deleteManagementUser(
      this.state.email,
      this.state.select,
      btoa(this.state.current_id)
    );
    if (data) {
      console.log("Successfully removed user");
      // this.updateList()
      this.getUsersList();
      this.handleClose();
      this.setState({ show3: false });
    } else {
      console.log("Something went wrong", data.error);
      this.setState({ message: data.error });
    }
  }

  handleClose() {
    this.setState({
      show: false,
      show1: false,
      show2: false,
      show3: false,
      message: "",
      emailError: false,
      emailExist: false,
      phoneError: false,
      user_nameError: false,
      first_nameError: false,
      request_access: false,
      usersCollection: [],
      modal: {},
      users: [],
      kitId: "",
      assignError: false,
      assignErrorMsg: "",
      blood_users: [],
      bloodKitId: "",
      assignBloodKitError: false,
      assignBloodKitErrorMsg: "",
      edit_id: "",
    });
  }

  async handleChange(e) {
    let name = e.target.name,
      value = e.target.value;
    let emailValid = false;
    let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
    switch (name) {
      case "email":
        if (!value.match(regexEmail)) {
          this.setState({
            emailError: true,
            emailExist: false,
            message: "",
          });
        } else {
          value = value.toLocaleLowerCase();
          this.setState({
            emailError: false,
            emailExist: false,
            message: "",
          });
        }
        break;
      case "user_name":
        if (!value) {
          this.setState({ user_nameError: true, user_nameMessage: "" });
        } else if (!usernameRegex.test(value)) {
          this.setState({
            user_nameError: true,
            user_nameMessage: "Please enter valid username.",
          });
        } else {
          this.setState({ user_nameError: false, user_nameMessage: "" });
        }

        break;
      case "first_name":
        if (!value) {
          this.setState({ first_nameError: true, first_nameMessage: "" });
        } else if (!nameRegex.test(value)) {
          this.setState({
            first_nameError: true,
            first_nameMessage: "Please enter a valid first name.",
          });
        } else {
          this.setState({ first_nameError: false, first_nameMessage: "" });
        }
        break;
      case "last_name":
        if (!value) {
          this.setState({ last_nameError: true, last_nameMessage: "" });
        } else if (!nameRegex.test(value)) {
          this.setState({
            last_nameError: true,
            last_nameMessage: "Please enter a valid last name.",
          });
        } else {
          this.setState({ last_nameError: false, last_nameMessage: "" });
        }
        break;
      case "phone":
        let phoneno = /^\+\d+$/;
        if (this.state.modal["email"] && this.state.modal["first_name"]) {
          if (!value.match(phoneno)) {
            this.setState({ phoneValid: true, message: "" });
          } else {
            this.setState({ phoneValid: false, message: "" });
          }
          if (!value) {
            this.setState({ phoneError: true, message: "" });
          } else {
            this.setState({ phoneError: false, message: "" });
          }
        } else {
          this.setState({
            emailError: this.state.modal["email"]
              ? this.state.modal["email"].match(regexEmail)
              : this.state.modal["email"],
            first_nameError: this.state.modal["first_name"],
            message: "",
          });
        }
        break;
      default:
        break;
    }
    this.state.modal[name] = value;
    if (this.state.modal.email && this.state.modal.email.match(regexEmail)) {
      emailValid = await this.validateEmail(this.state.modal.email);
      this.setState({ emailExist: emailValid, message: "" });
    }
  }

  handleOperation(modal, id) {
    this.setState({ current_id: id });
    switch (modal) {
      case "view":
        this.handleView(id);
        break;
      case "edit":
        this.handleEdit(id);
        break;
      case "delete":
        this.handleDelete(id);
        break;
      default:
        this.handleView(id);
        break;
    }
  }

  async resendEmail(modal, id) {
    this.setState({ current_id: id });
    let email = await apiService.resendUMEmail(this.state.email, btoa(id));
  }

  async handlePartnerSelect(e) {
    let { modal, select, current_type, users } = this.state;
    let selectedPartner = e.target.value;
    modal.partner_id = btoa(selectedPartner);
    console.log(
      selectedPartner,
      modal.partner_id,
      this.state.partner_id,
      "partner select"
    );
    let clinic = [],
      assignError = false,
      assignErrorMsg = "",
      assignBloodKitError = false,
      assignBloodKitErrorMsg = "";
    if (select == "practitioner") {
      clinic = await apiService.userPractClinic(
        this.state.email,
        this.state.select,
        modal.partner_id
      );
    }
    if (select == "clinic" || select == "practitioner") {
      const apiBody = {
        id: modal.partner_id,
      };
      let data = await apiService.accessManagementUser(apiBody);
      let list = getAccess(data);
      this.setState({ access: list.list });
    }

    if (users.length > 0 && selectedPartner == "select") {
      assignError = true;
      assignBloodKitError = true;
      assignErrorMsg = "Select a partner before assigning users";
      assignBloodKitErrorMsg = "Select a partner before assigning users";
    }
    this.setState({
      modal: modal,
      clinic: clinic,
      users: [],
      assignError,
      assignErrorMsg,
      blood_users: [],
      assignBloodKitError,
      assignBloodKitErrorMsg,
    });
  }

  async handleClinicSelect(e, action) {
    console.log("Action in clinic select", action);
    let modal = this.state.modal;
    let selectedClinicId = btoa(e.target.value);

    modal["clinic_id"] = e.target.value;
    let apiBody = {
      id: selectedClinicId,
    };
    if (action == "edit" && this.state.edit_id != "") {
      apiBody = {
        ...apiBody,
        action: "edit",
        edit_id: Buffer.from(String(this.state.edit_id)).toString("base64"),
      };
    }
    let data = await apiService.accessManagementUser(apiBody);
    let list = getAccess(data);
    this.setState({ modal, access: list.list });
  }

  async partnerList(condition) {
    let partners = await apiService.PartnerList(condition);
    this.setState({ partnerList: partners });
  }
  async subPartnerList(condition) {
    let subPractitioners = await apiService.ClinicList(condition);
    this.setState({ subPartnerList: subPractitioners });
  }

  async selectHandler(e) {
    this.setState({ loading: true });
    let type = e.target.name;
    let value = e.target.value;
    let condition = this.state.filter || {};
    let { selectedPartner, selectedClinic } = this.state;

    if (type == "clinic") {
      if (value != "select") {
        condition.clinic_id = btoa(value);
      } else {
        delete condition.clinic_id;
      }
      selectedClinic = value;
    }
    if (type == "partner") {
      // On changing partner, clear clinic id condition
      delete condition.clinic_id;
      selectedClinic = "select";
      let practitioner_id = btoa(this.state.current_pract);
      if (value != "select") {
        condition.partner_id = btoa(value);
        await this.subPartnerList({ practitioner_id, partner_id: btoa(value) });
        // await this.practitionerList({practitioner_id:btoa(this.state.current_pract),partner_id: btoa(value)})
      } else {
        delete condition.partner_id;
        await this.subPartnerList({ practitioner_id });
        // await this.practitionerList({practitioner_id:btoa(this.state.current_pract)})
      }
      selectedPartner = value;
    }
    this.setState(
      { filter: condition, skip: 0, selectedPartner, selectedClinic },
      () => this.getUsersList()
    );
    // this.updateUserFilter(condition, value)
  }

  async validateEmail(email) {
    let result = await apiService.validateUMEmail(email);
    if (result == true) {
      return true;
    } else {
      return false;
    }
  }

  async handlePagination(skip) {
    this.setState({ skip }, () => this.getUsersList());
  }

  render() {
    const {
      dropdown,
      show,
      show1,
      show2,
      show3,
      show4,
      modal,
      access,
      emailError,
      emailExist,
      user_nameError,
      kitId,
      kitSearch,
      blood_test_access,
      bloodKitSearch,
      blood_users,
      bloodKitId,
      assignBloodKitError,
      assignBloodKitErrorMsg,
      first_nameError,
      phoneError,
      phoneValid,
      message,
      loading,
      select,
      partner,
      clinic,
      current_type,
      clinic_name,
      selectedPartner,
      selectedClinic,
      partner_name,
      users,
      partnerList,
      subPartnerList,
      showPatients,
      assignError,
      assignErrorMsg,
      disablePartnerDropdown,
      disableClinicDropdown,
      user_nameMessage,
      first_nameMessage,
      last_nameError,
      last_nameMessage,
    } = this.state;
    const addModal = (
      <Modal
        show={show}
        onHide={() => this.handleClose()}
        centered
        className="trackingModal"
        size="sm"
        onEntered={() => this._input.focus()}
      >
        <Modal.Header closeButton style={{ backgroundColor: graph_color}}>
          <Modal.Title>Add {camelize(select)}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="user-management-modal">
          <>
            <Form>
              <Form.Group className="sign-field">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  placeholder="Enter Email"
                  type="email"
                  name="email"
                  value={modal.email}
                  onChange={(e) => this.handleChange(e)}
                  ref={(input) => (this._input = input)}
                />
                <Form.Text className="text-muted">
                  {emailError ? "Please Enter a valid Email" : null}
                  {emailExist ? "Email already exist in the DB" : null}
                </Form.Text>
              </Form.Group>
              <Form.Group className="sign-field">
                <Form.Label>User Name</Form.Label>
                <Form.Control
                  placeholder="Enter Username"
                  type="text"
                  name="user_name"
                  value={modal.user_name}
                  onChange={(e) => this.handleChange(e)}
                />
                <Form.Text className="text-muted">
                  {user_nameError
                    ? user_nameMessage || "Please Enter User Name"
                    : null}
                </Form.Text>
              </Form.Group>
              <Form.Group className="sign-field">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  placeholder="Enter FirstName"
                  type="text"
                  name="first_name"
                  value={modal.first_name}
                  onChange={(e) => this.handleChange(e)}
                />
                <Form.Text className="text-muted">
                  {first_nameError
                    ? first_nameMessage || "Please Enter First Name"
                    : null}
                </Form.Text>
              </Form.Group>
              <Form.Group className="sign-field">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  placeholder="Enter LastName"
                  type="text"
                  name="last_name"
                  value={modal.last_name}
                  onChange={(e) => this.handleChange(e)}
                />
                <Form.Text className="text-muted">
                  {last_nameError
                    ? last_nameMessage || "Please Enter Last Name"
                    : null}
                </Form.Text>
              </Form.Group>
              <Form.Group className="sign-field">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  placeholder="Enter Phone No"
                  type="text"
                  name="phone"
                  value={modal.phone}
                  onChange={(e) => this.handleChange(e)}
                />
                <Form.Text className="text-muted">
                  {phoneError ? "Please Enter Phone number" : null}
                  {phoneValid
                    ? "Please enter valid phone number eg:(+1987654321) "
                    : null}
                </Form.Text>
              </Form.Group>
              <Form.Group className="sign-field">
                {select == "partner" && current_type == "admin" ? (
                  <>
                    <Form.Label>Partner</Form.Label>
                    <Form.Group>
                      <Form.Control
                        as="select"
                        value={modal._id}
                        className="multiselect-patients"
                        onChange={(e) => this.handlePartnerSelect(e)}
                      >
                        <option key="sp0" value="select">
                          --Select Partner--
                        </option>
                        {partner &&
                          SortArray(partner, "username").map((user) => (
                            <option key={user._id} value={user._id}>
                              {user.username}
                            </option>
                          ))}
                      </Form.Control>
                    </Form.Group>
                  </>
                ) : null}
              </Form.Group>
              <Form.Group className="sign-field">
                {(select == "clinic" || select == "practitioner") &&
                  current_type == "admin" ? (
                  <>
                    <Form.Label>Partner</Form.Label>
                    <Form.Group>
                      <Form.Control
                        as="select"
                        value={modal._id}
                        className="multiselect-patients"
                        onChange={(e) => this.handlePartnerSelect(e)}
                      >
                        <option key="sp1" value="select">
                          --Select Partner--
                        </option>
                        {partner &&
                          SortArray(partner, null, "1334").map((user) => (
                            <option key={user._id} value={user._id}>
                              {user.first_name + " " + user.last_name}
                            </option>
                          ))}
                      </Form.Control>
                    </Form.Group>
                  </>
                ) : null}
              </Form.Group>
              <Form.Group className="sign-field">
                {select == "practitioner" &&
                  (current_type == "admin" || current_type == "partner") ? (
                  <>
                    <Form.Label>Clinic</Form.Label>
                    <Form.Group>
                      <Form.Control
                        as="select"
                        value={modal.clinic_id}
                        onChange={(e) => this.handleClinicSelect(e, "add")}
                      >
                        <option key="sc0" value="select">
                          --Select Clinic--
                        </option>
                        {clinic &&
                          SortArray(clinic).map((user) => (
                            <option key={user._id} value={user._id}>
                              {user.first_name + " " + user.last_name}
                            </option>
                          ))}
                      </Form.Control>
                    </Form.Group>
                  </>
                ) : null}
              </Form.Group>
              {(select == "clinic" || select == "practitioner") &&
                (current_type == "admin" ||
                  current_type == "partner" ||
                  current_type == "clinic") ? (
                <>
                  <Form.Group>
                    <Form.Label>
                      {blood_test_access
                        ? "Assign Genetic Customer"
                        : "Assign Customer"}
                      {kitSearch && (
                        <Spinner
                          className="spinnerClass"
                          animation="border"
                          variant="light"
                          size="sm"
                          style={{ paddingLeft: "5px", float: "center" }}
                        />
                      )}
                    </Form.Label>
                    <div>
                      {users &&
                        SortArray(users).map((u) => (
                          <Chip
                            color="info"
                            classes={"assign-user"}
                            label={u.username}
                            onDelete={() => this.removeUserKit(u)}
                          />
                        ))}
                    </div>
                    <Form.Control
                      placeholder="Type Kit Id and press Enter to search"
                      type="text"
                      name="kitId"
                      value={kitId}
                      onChange={(e) => this.enterKitId(e)}
                      onKeyPress={(e) => this.handleCustomerSearch(e)}
                    />
                    <Form.Text className="text-muted">
                      {assignError ? assignErrorMsg : null}
                    </Form.Text>
                  </Form.Group>
                  {blood_test_access && (
                    <Form.Group>
                      <Form.Label>
                        Assign Blood Customer
                        {bloodKitSearch && (
                          <Spinner
                            className="spinnerClass"
                            animation="border"
                            variant="light"
                            size="sm"
                            style={{ paddingLeft: "5px", float: "center" }}
                          />
                        )}
                      </Form.Label>
                      <div>
                        {blood_users &&
                          SortArray(blood_users).map((u) => (
                            <Chip
                              color="info"
                              classes={"assign-user"}
                              label={u.username}
                              onDelete={() => this.removeBloodKit(u)}
                            />
                          ))}
                      </div>
                      <Form.Control
                        placeholder="Type Blood Kit Id and press Enter to search"
                        type="text"
                        name="kitId"
                        value={bloodKitId}
                        onChange={(e) => this.enterBloodKitId(e)}
                        onKeyPress={(e) => this.handleBloodKitSearch(e)}
                      />
                      <Form.Text className="text-muted">
                        {assignBloodKitError ? assignBloodKitErrorMsg : null}
                      </Form.Text>
                    </Form.Group>
                  )}
                </>
              ) : null}
              <Form.Group>
                <Form.Label>Access Control</Form.Label>
                <MultiSelect
                  access={access}
                  handleAccessChanges={this.handleAccessChanges.bind(this)}
                  disabled={false}
                />
              </Form.Group>
              <Form.Group
                controlId="formBasicCheckbox"
                className="customCheckbox"
              >
                <input
                  checked={this.state.request_access}
                  onClick={() => {
                    this.setState({
                      request_access: !this.state.request_access,
                      show4: this.state.request_access ? false : true,
                    });
                  }}
                  type="checkbox"
                  label="Report Access"
                />
                <Form.Label>Report Access</Form.Label>
                <Form.Text style={{ color: "#fc7b30" }}>
                  If you provide “Report Access” to a Partner, Sub-Partner/
                  Clinic or Practitioner, they can view all customer reports.
                  This option should only be selected if you or the Partner,
                  Sub-Partner/ Clinic or Practitioner has the customer’s consent
                  prior to using the Helix Portal.
                </Form.Text>
              </Form.Group>
              <Form.Group className="sign-field">
                <Form.Text className="text-muted">
                  {message ? message : null}
                </Form.Text>
                <Button
                  className="continuebtn"
                  style={{ backgroundColor: graph_color }}
                  onClick={() => this.handleAddApi()}
                >
                  Add {select}{" "}
                  {loading ? (
                    <Spinner
                      className="spinnerClass"
                      animation="border"
                      variant="light"
                      size="sm"
                      style={{ float: "center" }}
                    />
                  ) : null}
                </Button>
              </Form.Group>
            </Form>
          </>
        </Modal.Body>
      </Modal>
    );
    const editModal = (
      <Modal
        show={show1}
        onHide={() => this.handleClose()}
        centered
        className="trackingModal"
        size="sm"
      >
        <Modal.Header closeButton style={{ backgroundColor: graph_color }}>
          <Modal.Title>
            Edit {select == "all" ? camelize(modal.type) : select}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="user-management-modal">
          <>
            <Form>
              <Form.Group className="sign-field">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  placeholder="Enter Email"
                  type="email"
                  name="email"
                  value={modal.email}
                  onChange={(e) => this.handleChange(e)}
                  required
                />
                <Form.Text className="text-muted">
                  {emailError ? "Please Enter a valid Email" : null}
                </Form.Text>
              </Form.Group>
              <Form.Group className="sign-field">
                <Form.Label>User Name</Form.Label>
                <Form.Control
                  placeholder="Enter Username"
                  type="text"
                  name="user_name"
                  value={modal.user_name}
                  //onChange={(e) => this.handleChange(e)}
                  disabled={true}
                />
                <Form.Text className="text-muted">
                  {user_nameError ? "Please Enter User Name" : null}
                </Form.Text>
              </Form.Group>
              <Form.Group className="sign-field">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  placeholder="Enter FirstName"
                  type="text"
                  name="first_name"
                  value={modal.first_name}
                  onChange={(e) => this.handleChange(e)}
                  required
                />
                <Form.Text className="text-muted">
                  {first_nameError ? "Please Enter First Name" : null}
                </Form.Text>
              </Form.Group>
              <Form.Group className="sign-field">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  placeholder="Enter LastName"
                  type="text"
                  name="last_name"
                  value={modal.last_name}
                  onChange={(e) => this.handleChange(e)}
                  required
                />
              </Form.Group>
              <Form.Group className="sign-field">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  placeholder="EnterPhone"
                  type="text"
                  name="phone"
                  value={modal.phone}
                  onChange={(e) => this.handleChange(e)}
                  required
                />
                <Form.Text className="text-muted">
                  {phoneError ? "Please Enter Phone number" : null}
                  {phoneValid
                    ? "Please enter valid phone number eg:(+1987654321) "
                    : null}
                </Form.Text>
              </Form.Group>
              <Form.Group className="sign-field">
                <Form.Label>Partner</Form.Label>
                <Form.Control
                  placeholder="Partner"
                  type="text"
                  name="partner_id"
                  value={partner_name}
                  onChange={(e) => this.handleChange(e)}
                  required
                  disabled
                />
              </Form.Group>
              {modal.type === "practitioner" ? (
                <Form.Group className="sign-field">
                  <Form.Label>Clinic</Form.Label>
                  {current_type == "admin" || current_type == "partner" ? (
                    <Form.Group>
                      <Form.Control
                        as="select"
                        value={modal.clinic_id}
                        onChange={(e) => this.handleClinicSelect(e, "edit")}
                      >
                        <option key="sc1" value="select">
                          --Select Clinic--
                        </option>
                        {clinic &&
                          SortArray(clinic).map((user) => (
                            <option key={user._id} value={user._id}>
                              {user.first_name + " " + user.last_name}
                            </option>
                          ))}
                      </Form.Control>
                    </Form.Group>
                  ) : (
                    <Form.Control
                      placeholder="clinic_id"
                      type="text"
                      name="clinic_id"
                      value={clinic_name}
                      onChange={(e) => this.handleChange(e)}
                      required
                      disabled
                    />
                  )}
                </Form.Group>
              ) : null}
              {showPatients === true ? (
                <>
                  <Form.Group>
                    <Form.Label>
                      {blood_test_access
                        ? "Assign Genetic Customer"
                        : "Assign Customer"}
                      {kitSearch && (
                        <Spinner
                          className="spinnerClass"
                          animation="border"
                          variant="light"
                          size="sm"
                          style={{ paddingLeft: "5px", float: "center" }}
                        />
                      )}
                    </Form.Label>
                    <div>
                      {users &&
                        SortArray(users).map((u) => (
                          <Chip
                            color="info"
                            classes={"assign-user"}
                            label={u.username}
                            onDelete={() => this.removeUserKit(u)}
                          />
                        ))}
                    </div>
                    <Form.Control
                      placeholder="Type Kit Id and press Enter to search"
                      type="text"
                      name="kitId"
                      value={kitId}
                      onChange={(e) => this.enterKitId(e)}
                      onKeyPress={(e) => this.handleCustomerSearch(e)}
                    />
                    <Form.Text className="text-muted">
                      {assignError ? assignErrorMsg : null}
                    </Form.Text>
                  </Form.Group>
                  {blood_test_access && (
                    <Form.Group>
                      <Form.Label>
                        Assign Blood Customer
                        {bloodKitSearch && (
                          <Spinner
                            className="spinnerClass"
                            animation="border"
                            variant="light"
                            size="sm"
                            style={{ paddingLeft: "5px", float: "center" }}
                          />
                        )}
                      </Form.Label>
                      <div>
                        {blood_users &&
                          SortArray(blood_users).map((u) => (
                            <Chip
                              color="info"
                              classes={"assign-user"}
                              label={u.username}
                              onDelete={() => this.removeBloodKit(u)}
                            />
                          ))}
                      </div>
                      <Form.Control
                        placeholder="Type Blood Kit Id and press Enter to search"
                        type="text"
                        name="kitId"
                        value={bloodKitId}
                        onChange={(e) => this.enterBloodKitId(e)}
                        onKeyPress={(e) => this.handleBloodKitSearch(e)}
                      />
                      <Form.Text className="text-muted">
                        {assignBloodKitError ? assignBloodKitErrorMsg : null}
                      </Form.Text>
                    </Form.Group>
                  )}
                </>
              ) : null}
              <Form.Group>
                <Form.Label>Access Control</Form.Label>
                <MultiSelect
                  access={access}
                  handleAccessChanges={this.handleAccessChanges.bind(this)}
                  disabled={false}
                />
              </Form.Group>
              <Form.Group
                controlId="formBasicCheckbox"
                className="customCheckbox"
              >
                <input
                  checked={this.state.request_access}
                  onClick={() => {
                    this.setState({
                      request_access: !this.state.request_access,
                      show4: this.state.request_access ? false : true,
                    });
                  }}
                  type="checkbox"
                  label="Report Access"
                />
                <Form.Label>Report Access</Form.Label>
                <Form.Text style={{ color: "#fc7b30" }}>
                  If you provide “Report Access” to a Partner, Sub-Partner/
                  Clinic or Practitioner, they can view all customer reports.
                  This option should only be selected if you or the Partner,
                  Sub-Partner/ Clinic or Practitioner has the customer’s consent
                  prior to using the Helix Portal.
                </Form.Text>
              </Form.Group>
              <Form.Group className="sign-field">
                <Button
                  className="continuebtn"
                  style={{ backgroundColor: graph_color }}
                  onClick={() => this.handleEditApi()}
                >
                  Edit {select == "all" ? modal.type : select}{" "}
                  {loading ? (
                    <Spinner
                      className="spinnerClass"
                      animation="border"
                      variant="light"
                      size="sm"
                      style={{ float: "center" }}
                    />
                  ) : null}
                </Button>
              </Form.Group>
            </Form>
          </>
        </Modal.Body>
      </Modal>
    );
    const viewModal = (
      <Modal
        show={show2}
        onHide={() => this.handleClose()}
        centered
        className="trackingModal"
        size="sm"
      >
        <Modal.Header closeButton style={{ backgroundColor: graph_color }}>
          <Modal.Title>
            View {select == "all" ? modal.type : select}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="user-management-modal">
          {modal ? (
            <>
              <Form>
                <Form.Group className="sign-field">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    placeholder="Email"
                    type="email"
                    name="email"
                    value={modal.email}
                    disabled
                  />
                </Form.Group>
                <Form.Group className="sign-field">
                  <Form.Label>User Name</Form.Label>
                  <Form.Control
                    placeholder="Enter Username"
                    type="text"
                    name="user_name"
                    value={modal.user_name}
                    disabled
                  />
                </Form.Group>
                <Form.Group className="sign-field">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    placeholder="Name"
                    type="text"
                    name="name"
                    value={modal.first_name + " " + modal.last_name}
                    disabled
                  />
                </Form.Group>
                <Form.Group className="sign-field">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    placeholder="Phone"
                    type="text"
                    name="phone"
                    value={modal.phone}
                    disabled
                  />
                </Form.Group>
                <Form.Group className="sign-field">
                  <Form.Label>Partner</Form.Label>
                  <Form.Control
                    placeholder="Partner"
                    type="text"
                    name="partner_id"
                    value={partner_name}
                    disabled
                  />
                </Form.Group>
                <Form.Group className="sign-field">
                  <Form.Label>Clinic</Form.Label>
                  <Form.Control
                    placeholder="clinic_id"
                    type="text"
                    name="clinic_id"
                    value={clinic_name}
                    disabled
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Access Control</Form.Label>
                  <MultiSelect
                    access={access}
                    handleAccessChanges={this.handleAccessChanges.bind(this)}
                    disabled={true}
                  />
                </Form.Group>
              </Form>
            </>
          ) : (
            <h1>No result was found</h1>
          )}
        </Modal.Body>
      </Modal>
    );
    const deleteModal = (
      <Modal
        show={show3}
        onHide={() => this.handleClose()}
        centered
        className="trackingModal"
        size="sm"
      >
        <Modal.Header closeButton style={{ backgroundColor: graph_color }}>
          <Modal.Title>
            Delete {select == "all" ? modal.type : select}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Are you sure you want to delete {modal.email} user</h5>
          <br />
          <Form.Text className="text-muted">
            {message ? message : null}
          </Form.Text>
          <Button
            className="bgBtn mr-3"
            type="submit"
            size="sm"
            onClick={() => this.handleDeleteApi()}
          >
            {this.state.submitted ? (
              <Spinner
                className="spinnerClass"
                animation="border"
                variant="light"
                size="sm"
                style={{ float: "center" }}
              />
            ) : (
              "YES"
            )}
          </Button>
          <Button
            className="bgBtn mr-3"
            type="submit"
            size="sm"
            onClick={() => this.handleClose()}
          >
            NO
          </Button>
        </Modal.Body>
      </Modal>
    );
    const reportModal = (
      <Modal
        show={show4}
        onHide={() => {
          this.setState({ request_access: false, show4: false });
        }}
        className="reportaccess_warning"
        centered
        size="sm"
      >
        <Modal.Header closeButton style={{ backgroundColor: graph_color }}>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body className="user-management-modal">
          <h5>
            You are legally responsible to collect the customer’s consent prior
            to accessing customer information/ data.
          </h5>
          <Button
            style={{ marginLeft: "34%", marginTop: "2%" }}
            className="bgBtn mr-3"
            type="submit"
            size="sm"
            onClick={() => {
              this.setState({ request_access: true, show4: false });
            }}
          >
            OK
          </Button>
        </Modal.Body>
      </Modal>
    );
    return (
      <>
        <div className="home-wrp">
          <div className="homepagehead">
            <div className="row justify-content-between align-items-end">
              <div className="col-12 col-sm-auto">
                <h1>Clinic/ Practitioner Management</h1>
                <BreadCrumb title="Clinic/ Practitioner Management"></BreadCrumb>
              </div>
              <div className="col-12 col-sm-auto d-flex">
                <FilerButton
                  types={dropdown}
                  updateList={this.handleFilterSelect.bind(this)}
                ></FilerButton>
                {/* <FilerButtonNew partner={partner} clinic={clinic} practitioner={practitioner} handleFilter={() => this.handleFilter()} onClick={() => this.handleFilter()}></FilerButtonNew> */}
                <SearchPractBox
                  email={this.state.email}
                  limit={this.state.limit}
                  skip={this.state.skip}
                  updateUsersList={this.handleSearch.bind(this)}
                ></SearchPractBox>
              </div>
            </div>
          </div>
          <div className="selectionBar-admin">
            <div className="innerpagesFiltration">
              <div className="row align-items-center justify-content-between">
                <div className="col-12 col-sm-auto">
                  <ul className="list-unstyled-um">
                    {current_type == "admin" && (
                      <li key={"partner"}>
                        <h6>Partner : </h6>{" "}
                        <Form.Group>
                          <Form.Control
                            as="select"
                            name="partner"
                            value={selectedPartner}
                            disabled={disablePartnerDropdown}
                            onChange={this.selectHandler.bind(this)}
                          >
                            <option key="sp2" value="select">
                              --Select Partner--
                            </option>
                            {!disablePartnerDropdown &&
                              partnerList &&
                              SortArray(partnerList, null, "Partner 1730").map(
                                (user) => (
                                  <option key={user._id} value={user._id}>
                                    {user.first_name + " " + user.last_name}
                                  </option>
                                )
                              )}
                          </Form.Control>
                        </Form.Group>{" "}
                      </li>
                    )}
                    {(current_type == "admin" || current_type == "partner") && (
                      <li key={"clinic"}>
                        <h6>Sub-Partner : </h6>{" "}
                        <Form.Group>
                          <Form.Control
                            as="select"
                            name="clinic"
                            value={selectedClinic}
                            disabled={disableClinicDropdown}
                            onChange={this.selectHandler.bind(this)}
                          >
                            <option key="sc2" value="select">
                              --Select Sub-Partner--
                            </option>
                            {!disableClinicDropdown &&
                              subPartnerList &&
                              SortArray(
                                subPartnerList,
                                null,
                                "Partner 1730"
                              ).map((user) => (
                                <option key={user.id} value={user._id}>
                                  {user.first_name + " " + user.last_name}
                                </option>
                              ))}
                          </Form.Control>
                        </Form.Group>{" "}
                      </li>
                    )}
                  </ul>
                </div>
                <div className="col-12 col-sm-auto">
                  {select !== "all" && select != "1" ? (
                    <button className="addBtn" onClick={() => this.handleAdd()}>
                      {" "}
                      <i
                        className="bi bi-person-plus-fill"
                        fill={graph_color}
                      ></i>{" "}
                      Add {camelize(select)}{" "}
                      {loading ? (
                        <Spinner
                          className="spinnerClass"
                          animation="border"
                          size="sm"
                          style={{ float: "center" }}
                        />
                      ) : null}
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="patientsTable">
            {loading ? (
              <Spinner
                className="spinnerClass"
                animation="border"
                style={{
                  float: "center",
                  position: "absolute",
                  margin: "10% 10%",
                  left: "45%",
                  top: "25%",
                }}
              />
            ) : null}
            <UserTable
              userDetails={this.state.user_details}
              practitionerId={this.state.practitioner_id}
              updateUser={this.updateStatus.bind(this)}
              loading_status={this.state.request_loading}
              handleOperation={this.handleOperation.bind(this)}
              resendEmail={this.resendEmail.bind(this)}
              select={this.state.select}
              skip={this.state.skip}
            ></UserTable>
          </div>
          <div className="d-flex justify-content-end">
            <Pagination
              paginationClick={(num) => this.handlePagination(num)}
              total={this.state.total}
              pages={this.state.pages}
              skip={this.state.skip}
              limit={this.state.limit}
            ></Pagination>
          </div>
        </div>

        {addModal}
        {editModal}
        {viewModal}
        {deleteModal}
        {reportModal}
      </>
    );
  }
}

function camelize(str, removeUnderscores = false) {
  if (!str) return;

  if (removeUnderscores) str = str.replace(/_/g, " ");

  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toUpperCase() : word.toUpperCase();
    })
    .replace(/\s+/g, " ");
}

function getAccess(access) {
  let flag = true;
  let list = Object.entries(access).reduce((acc, [key, value]) => {
    if (typeof value === "object") {
      const { list, flag } = getAccess(value);
      if (Object.keys(list).length > 0) {
        acc.push({
          label: camelize(key, true),
          value: key,
          condition: flag,
          children: list,
        });
      }
    } else if (typeof value === "boolean") {
      if (flag === true) flag = value;
      acc.push({
        label: camelize(key, true),
        value: key,
        condition: value,
      });
    }
    return acc;
  }, []);
  return { list, flag };
}

function mapState(state) {
  const { loggingIn, user } = state.authentication;
  return { loggingIn, user };
}

const actionCreators = {
  success: alertActions.success,
};

export default connect(mapState, actionCreators)(UserManagement);
